import React, { Component } from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { MarkerF } from "@react-google-maps/api";
import "./marker.css";
import { Typography } from "@mui/material";

const containerStyle = {
  width: "300px",
  height: "300px",
};

const location = {
  address: "Emilio lailhacar 6051, San Miguel.",
  lat: -33.51173201160969,
  lng: -70.65177350003998,
}; // our location object from earlier

const onLoad = (marker) => {
  console.log("marker: ", marker);
};

export default class Gmap extends Component {
  render() {
    return (
      <LoadScript googleMapsApiKey="AIzaSyD-1E5rs8-Nl0YSsVy5a9v_43sKLus3Oeo">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={location}
          zoom={17}
        >
          <MarkerF
            onLoad={onLoad}
            position={location}
            // label={location.address}
          />
        </GoogleMap>
      </LoadScript>
    );
  }
}
