import React, { useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import ExperienceSection from "../components/ExperienceSection";

const Experience = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div style={{ backgroundColor: "#283556" }}>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <div style={{ height: "80px", backgroundColor: "#283556" }}></div>
      <ExperienceSection />
      <div style={{ height: "140px", backgroundColor: "#283556" }}></div>
      <Footer />
    </div>
  );
};

export default Experience;
