import React, { useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import TeamSection from "../components/TeamSection";

const Team = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div style={{ backgroundColor: "#283556" }}>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <div style={{ height: "80px", backgroundColor: "#283556" }}></div>
      <TeamSection />
      <div style={{ height: "20px", backgroundColor: "#283556" }}></div>
      <Footer />
    </div>
  );
};

export default Team;
