import React from "react";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
  Header,
} from "./ServicesElements";
import Icon1 from "../../images/svg-1.svg";
import Icon2 from "../../images/svg-2.svg";
import Icon3 from "../../images/svg-1.svg";
import { Grid, Typography } from "@mui/material";

import Imagen1 from "../../images/servicios/1.jpg";
import Imagen2 from "../../images/servicios/2.jpg";
import Imagen3 from "../../images/servicios/3.jpg";
import Imagen4 from "../../images/servicios/4.jpg";
import Imagen5 from "../../images/servicios/5.jpg";
import Imagen6 from "../../images/servicios/6.jpg";
import Imagen7 from "../../images/servicios/7.jpg";

const Services = () => {
  return (
    <Header id="services">
      {/* <ServicesH1> */}
      <Grid style={{ display: "flex", textAlign: "center" }}>
        <Typography
          variant="h3"
          sx={{ color: "#fff", marginBottom: "64px", marginTop: "80px" }}
        >
          Nuestros servicios
        </Typography>
      </Grid>
      <Grid
        container
        xs={8}
        spacing={2}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item xs={12} sm={4}>
          <ServicesCard>
            <ServicesIcon src={Imagen1} />
            <Typography variant="caption">
              Estudios de Demanda y Evaluación Social de Proyectos de
              Infraestructura de Transporte
            </Typography>
            {/* <ServicesP>We help reduce your fess and increase your overall revenue.</ServicesP> */}
          </ServicesCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ServicesCard>
            <ServicesIcon src={Imagen5} />
            <Typography variant="caption">
              Estudios de Optimización de la Movilidad de Personas y Mercancías
              (Logística)
            </Typography>
            {/* <ServicesP>You can acces our platform online anywhere in the world.</ServicesP> */}
          </ServicesCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ServicesCard>
            <ServicesIcon src={Imagen2} />
            <Typography variant="caption">
              Estudios de Capacidad Vial de Planes Reguladores (ECV)
            </Typography>
            {/* <ServicesP>Unlock our special membership that returns 5% cash back.</ServicesP> */}
          </ServicesCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ServicesCard>
            <ServicesIcon src={Imagen7} />
            <Typography variant="caption">
              Estudios de Impacto en la Movilidad (IMIV).
            </Typography>
            {/* <ServicesP>Unlock our special membership that returns 5% cash back.</ServicesP> */}
          </ServicesCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ServicesCard>
            <ServicesIcon src={Imagen6} />
            <Typography variant="caption">
              Estudios de Demanda-Recaudación y Modelo de Negocio
              Infraestructura de Transporte Concesionada.
            </Typography>
            {/* <ServicesP>Unlock our special membership that returns 5% cash back.</ServicesP> */}
          </ServicesCard>
        </Grid>
      </Grid>
    </Header>
  );
};

export default Services;
