import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRigths,
  SocialIcons,
  SocialIconLink,
} from "./FooterElements";
import { animateScroll as scroll } from "react-scroll";
import { Typography } from "@mui/material";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        {/* <FooterLinksContainer>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>About us </FooterLinkTitle>
                        <FooterLink to="/signin">How it works</FooterLink>
                        <FooterLink to="/signin">Testimonials</FooterLink>
                        <FooterLink to="/signin">Carrers</FooterLink>
                        <FooterLink to="/signin">Investors</FooterLink>
                        <FooterLink to="/signin">Terms of Service</FooterLink>    
                    </FooterLinkItems>
                    <FooterLinkItems>
                        <FooterLinkTitle>About us </FooterLinkTitle>
                        <FooterLink to="/signin">How it works</FooterLink>
                        <FooterLink to="/signin">Testimonials</FooterLink>
                        <FooterLink to="/signin">Carrers</FooterLink>
                        <FooterLink to="/signin">Investors</FooterLink>
                        <FooterLink to="/signin">Terms of Service</FooterLink>    
                    </FooterLinkItems>
                </FooterLinksWrapper>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>About us </FooterLinkTitle>
                        <FooterLink to="/signin">How it works</FooterLink>
                        <FooterLink to="/signin">Testimonials</FooterLink>
                        <FooterLink to="/signin">Carrers</FooterLink>
                        <FooterLink to="/signin">Investors</FooterLink>
                        <FooterLink to="/signin">Terms of Service</FooterLink>    
                    </FooterLinkItems>
                    <FooterLinkItems>
                        <FooterLinkTitle>About us </FooterLinkTitle>
                        <FooterLink to="/signin">How it works</FooterLink>
                        <FooterLink to="/signin">Testimonials</FooterLink>
                        <FooterLink to="/signin">Carrers</FooterLink>
                        <FooterLink to="/signin">Investors</FooterLink>
                        <FooterLink to="/signin">Terms of Service</FooterLink>    
                    </FooterLinkItems>
                </FooterLinksWrapper>
            </FooterLinksContainer> */}
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              <div className="column">
                <div className="row">
                  <Typography style={{ fontSize: "18px" }}>
                    ETD Consulting
                  </Typography>
                </div>
                <div className="row">
                  <Typography style={{ fontSize: "12px" }}>
                    Engineering, Technology and Design
                  </Typography>
                </div>
              </div>
            </SocialLogo>
            <WebsiteRigths className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
              {/* <h6 className="text-uppercase  font-weight-bold" style={{fontSize:"14px", color:"white", marginLeft:"60px"}}>Contacto</h6> */}
              <p
                style={{
                  fontSize: "12px",
                  color: "white",
                  marginBottom: "0px",
                }}
              >
                <i className="fas fa-home mr-3"></i> Emilio Lailhacar 6051, San
                Miguel
              </p>
              <a
                style={{ fontSize: "12px", color: "white" }}
                href="mailto:info@etd-consulting.cl"
              >
                <i class="fas fa-envelope mr-3"></i> info@etd-consulting.cl
              </a>
              <p style={{ fontSize: "12px", color: "white" }}>
                <i className="fas fa-phone mr-3"></i> + 569 8828 2014
              </p>
            </WebsiteRigths>
            <WebsiteRigths>
              {" "}
              ETD &copy; {new Date().getFullYear()} All rights reserved.
            </WebsiteRigths>
            {/* <SocialIcons>
                        <SocialIconLink href="/" target="_blank" aria-label="Facebook">
                            <FaFacebook/>
                        </SocialIconLink>
                        <SocialIconLink href="/" target="_blank" aria-label="Instagram">
                            <FaInstagram/>
                        </SocialIconLink>
                        <SocialIconLink href="/" target="_blank" aria-label="Youtube">
                            <FaYoutube/>
                        </SocialIconLink>
                        <SocialIconLink href="/" target="_blank" aria-label="Twitter">
                            <FaTwitter/>
                        </SocialIconLink>
                        <SocialIconLink href="/" target="_blank" aria-label="Linkedin">
                            <FaLinkedin/>
                        </SocialIconLink>
                    </SocialIcons> */}
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
