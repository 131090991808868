import React, { useState } from "react";
import Footer from "../components/Footer";
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection";
import AboutUsSection from "../components/AboutUsSection";
import {
  Equipo,
  HomeObjFive,
  HomeObjFour,
  HomeObjOne,
  HomeObjSix,
  HomeObjThree,
  HomeObjTwo,
} from "../components/InfoSection/Data";
import Navbar from "../components/Navbar";
import Services from "../components/ServicesSection";
import Sidebar from "../components/Sidebar";
import ClientsSection from "../components/ClientsSection";
import ContactFormSection from "../components/ContactSection/index.js";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      {/* <AboutUsSection />
      <Services />
      <InfoSection {...Equipo} />
      <InfoSection {...HomeObjThree} />
      <InfoSection {...HomeObjFour} />
      <ClientsSection />
      <ContactFormSection /> */}
      <Footer />
    </>
  );
};

export default Home;
