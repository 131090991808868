import styled from "styled-components";

export const InfoContainer = styled.div`
    color:#fff;
    background: #f9f9f9;

    @media screen and (max-width: 768px) {
        padding: 100px 0;
    }
`

export const InfoWrapper = styled.div`
    z-index: 1;
    height: 860px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;

    @media screen and (max-width: 768px) {
        height: 430px;
    }
`

export const ServicesContainer = styled.div`
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f9f9f9;
    background-color: #283556 ;

    @media screen and (max-width: 768px) {
        padding: 100px 0;
    }
`

export const ServicesWrapper = styled.div`
    max-width: 1100px;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 768px) {
        height: 430px;
        max-width: 600px;
    }
`

export const ImgWrap = styled.div`
    max-width: 300px;
    height: 100%;
`

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`

export const Img2 = styled.img`
    width: 100%;
    margin: 40px 0 10px 0;
    padding-right: 0;
    background-color: #010606;
`

export const Img3 = styled.img`
    width: 100%;
    margin: 40px 0 10px 0;
    padding-right: 0;
`