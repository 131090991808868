import React from "react";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
  Header,
} from "./ServicesElements";
import { Grid, List, ListItem, ListItemText, Typography } from "@mui/material";

import DownloadButton from "./DownloadButton";
import PdfPreviewButton from "./PreviewButton";
import PreviewPDF from "./PreviewButton";
import BotonPDF from "./BotonPDF";

const ExperienceSection = () => {
  return (
    <Header id="services">
      {/* <ServicesH1> */}
      <Grid style={{ display: "flex", textAlign: "center" }}>
        <Typography
          variant="h3"
          sx={{ color: "#fff", marginBottom: "64px", marginTop: "80px" }}
        >
          Nuestra experiencia
        </Typography>
      </Grid>
      <Grid container xs={8} spacing={2}>
        <Grid item xs={12} sm={12}>
          <ServicesCard>
            <Grid>
              <Typography variant="subtitle1">
                Estudios Recientes y en Ejecución
              </Typography>
              <List dense={1}>
                <ListItem>
                  <ListItemText primary="- Impacto Desvíos de Tránsito Proyecto Conexión Vial Ruta 68-Ruta 78, Steer-Grupo Costanera (En Ejecución)" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="- Estudio de Impacto en Movilidad (IMIV) Normalización Hospital Barros Luco Trudeau, MSTD-Servicio de Salud Metropolitano Sur (En Ejecución)" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="- Estudio Capacidad Vial Plan Regulador de la Comuna de Lo Prado, Cliente: Habiterra Ltda. - Municipalidad de Lo Prado (En Ejecución)" />
                </ListItem>
                <ListItem>
                  <ListItemText primary='- Estudio de Demanda y Evaluación Social IP "Accesos a Valdivia" Fase 2, RYQ Concesiones - Dirección General de Concesiones MOP (En Ejecución)' />
                </ListItem>
                <ListItem>
                  <ListItemText primary="- Estudio Construcción Modelo Táctico Proyecto Ampliación Terminal Aeropuerto Arturo Merino Benítez, Cliente: W Ingeniería - Constructora CJV/VCGP-Astaldi (En Ejecución)" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="- Estudio de Impacto en Movilidad (IMIV) Planta NAIPU Colina, Cliente: W Ingeniería-Naipu Mining (2023)" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="- Estudio de Demanda y Evaluación Social IP Ruta Metropolitana de Puerto Montt, Cliente: RyQ Concesiones - Dirección General de Concesiones (2023)" />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary='- Estudio de Demanda y Evaluación Social IP "Accesos a Valdivia" Fase 1, RYQ Concesiones - Dirección General de Concesiones MOP (2021)'
                    // secondary={secondary ? "Secondary text" : null}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary="- Plan Maestro Movilidad y Transporte Isla Cautín, Comuna de Temuco, MSTD - Ministerio de Vivienda y Urbanismo (2021)" />
                </ListItem>
              </List>
            </Grid>
            {/* <Typography variant="body2">
              Puedes descargar un archivo que indica todos los proyectos en los
              que se ha formado parte
            </Typography> */}
            {/* <DownloadButton
              filepath="Experiencia_ETD.pdf"
              filename="Experiencia_ETD.pdf"
            /> */}
            <BotonPDF />
            {/* <PreviewPDF
              pdfUrl="Experiencia_ETD.pdf"
              pdfName="Experiencia_ETD.pdf"
            /> */}
            {/* <ServicesP>We help reduce your fess and increase your overall revenue.</ServicesP> */}
          </ServicesCard>
        </Grid>
      </Grid>
    </Header>
  );
};

export default ExperienceSection;
