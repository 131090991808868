import React from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const DownloadButton = ({ filepath, filename }) => {
  //   const classes = useStyles();

  const handleClick = () => {
    const link = document.createElement("a");
    link.href = `${process.env.PUBLIC_URL}${filepath}`;
    link.download = filename;
    link.click();
  };

  return (
    <Button
      variant="contained"
      color="primary"
      //   className={classes.button}
      onClick={handleClick}
    >
      Descargar
    </Button>
  );
};

export default DownloadButton;
