import React from "react";
import experienciaPDF from "./Files/Experiencia_ETD.pdf";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const BotonPDF = () => {
  const abrirPDF = () => {
    window.open(experienciaPDF);
  };

  return <Button onClick={abrirPDF}>Ver listado completo de proyectos</Button>;
};

export default BotonPDF;
