import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { LinkedIn } from "@mui/icons-material";
import { styled } from "@mui/system";

import { makeStyles } from "@mui/styles";
import CardFlip from "react-card-flip";

const FlippableCard = (props) => {
  const HoverCard = styled(Card)({
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.05)",
      cursor: "pointer",
    },
  });

  const [isFlipped, setIsFlipped] = useState(false);
  const frontRef = useRef(null);
  const backRef = useRef(null);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  useEffect(() => {
    const frontHeight = frontRef.current.offsetHeight;
    backRef.current.style.height = `${frontHeight}px`;
  }, [isFlipped]);
  return (
    <>
      <CardFlip
        className="flippable-card"
        isFlipped={isFlipped}
        flipDirection="horizontal"
      >
        <HoverCard
          // className={classes.card}
          key="front"
          onClick={handleClick}
          style={{ padding: "10px" }}
          ref={frontRef}
          // style={{ height: "50%" }}
          // onClick={handleClick1}
        >
          <Avatar
            src={props.foto}
            alt={props.nombre}
            style={{ width: "200px", height: "200px", margin: "auto" }}
          />
          <CardContent>
            <Typography variant="h5" align="center">
              {props.nombre}
            </Typography>
            <Typography sx={{ color: "grey" }} align="center">
              {props.cargo}
            </Typography>
            <Typography sx={{ color: "grey" }} align="center">
              {props.carrera}
            </Typography>
            {/* <Typography variant="body1">
                  Some text that describes me lorem ipsum ipsum lorem.
                </Typography> */}
            <Typography variant="body2" align="center">
              {props.correo}
            </Typography>

            {/* <p>
                  <button class="button">Contact</button>
                </p> */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link href={props.link}>
                <LinkedIn />
              </Link>
              {/* <FaLinkedin /> */}
            </div>
          </CardContent>
        </HoverCard>
        <HoverCard key="back" onClick={handleClick} ref={backRef}>
          <CardContent>
            <Typography variant="h5" align="center">
              {props.nombre}
            </Typography>
            <Typography
              variant="body2"
              align="justify"
              sx={{ marginTop: "10px", fontSize: "" }}
            >
              {props.detalle}
            </Typography>
          </CardContent>
        </HoverCard>
      </CardFlip>
    </>
  );
};

export default FlippableCard;
