import React, { useState } from "react";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./HeroElements";
import Video from "../../videos/hero-video.mp4";
import { Button } from "../ButtonElements";

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id="home">
      <HeroBg className="video-container" style={{ pointerEvents: "none" }}>
        <VideoBg
          className="video-container"
          autoPlay
          loop
          muted
          src={Video}
          type="video/mp4"
          playsInline
          controls={false}
        />
      </HeroBg>
      <HeroContent>
        <HeroH1>ETD Consulting</HeroH1>
        <HeroP>Engineering, Technology and Design</HeroP>
        {/** 
          <HeroBtnWrapper>
            <Button 
            to="signup" onMouseEnter={onHover} onmouseleave={onHover} primary='true' dark='true'
            smooth={true} duration={500} spy={true} exact='true' offset={-80}
            >
              Get started {hover ? <ArrowForward/> : <ArrowRight/>}
            </Button>
          </HeroBtnWrapper>*/}
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
