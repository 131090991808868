import React from "react";
import {
  Header,
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesCard2,
  ServicesIcon2,
  ServicesH2,
  ServicesP,
  ServicesP2,
} from "./AboutUsElements";
import Icon1 from "../../images/svg-1.svg";
import Icon2 from "../../images/svg-2.svg";
import Icon3 from "../../images/svg-1.svg";
import { Grid, Typography, Paper } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import { styled } from "@mui/styles";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaBuilding, FaEye, FaFlagCheckered, FaRegEye } from "react-icons/fa";

AOS.init();

const MyPaper = styled(Paper)({
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  padding: "30px",
});

const AboutUsSection = () => {
  return (
    <Header id="about">
      {/*<Sección: Nosotros/>*/}
      <Typography variant="h3" sx={{ color: "#fff", marginBottom: "64px" }}>
        Quiénes somos
      </Typography>
      <Grid container justifyContent="center" sx={{ marginTop: "-50px" }}>
        <Grid
          item
          xs={10}
          md={5}
          style={{ margin: "0.5em", direction: "column" }}
        >
          <MyPaper
            data-aos="fade-in"
            elevation={3}
            // sx={{ backgroundColor: "#004AAD" }}
            sx={{ backgroundColor: "#003882" }}
          >
            <Grid
              sx={{ minHeight: 180 }}
              container
              direction="column"
              justifyContent="center"
            >
              <Grid item>
                <FaBuilding
                  style={{ height: "2em", width: "2em", color: "white" }}
                />
                {/* <BusinessIcon
                  style={{ height: "2em", width: "2em", color: "white" }}
                /> */}
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    color: "white",
                    fontWeight: 500,
                    fontSize: "1rem",
                    marginBottom: "10px",
                  }}
                >
                  Historia
                </Typography>
              </Grid>
              <Typography sx={{ color: "white", fontSize: "0.9rem" }}>
                ETD Consulting E.I.R.L. es una empresa de ingeniería
                especializada en las áreas de Ingeniería de Transporte,
                Tecnología y Diseño. La empresa fue creada a fines del año 2021
                por Gabriel Vergara Hernández, Ingeniero Civil en Transporte con
                más de 20 años de experiencia en el rubro
              </Typography>
            </Grid>
          </MyPaper>
        </Grid>
        <Grid item xs={10} md={5} style={{ margin: "0.5em" }}>
          <MyPaper
            data-aos="fade-in"
            elevation={3}
            // sx={{ backgroundColor: "#004AAD" }}
            sx={{ backgroundColor: "#003882" }}
          >
            <Grid
              sx={{ minHeight: 180 }}
              container
              direction="column"
              justifyContent="center"
            >
              <Grid item>
                <FaEye
                  style={{ height: "2em", width: "2em", color: "white" }}
                />
                {/* <LightbulbIcon
                style={{ height: "2em", width: "2em", color: "white" }}
                /> */}
                <Typography
                  sx={{
                    color: "white",
                    fontWeight: 500,
                    fontSize: "1rem",
                    marginBottom: "10px",
                  }}
                >
                  Visión
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: "white", fontSize: "0.9rem" }}>
                  Ser una empresa líder en el área de la consultoría en
                  ingeniería de Transporte, Tecnología y Diseño que contribuya a
                  hacer de este país y del mundo un lugar mejor para vivir.
                </Typography>
              </Grid>
            </Grid>
          </MyPaper>
        </Grid>
        <Grid item xs={10} md={8} style={{ margin: "0.5em" }}>
          <MyPaper
            data-aos="fade-in"
            elevation={3}
            // sx={{ backgroundColor: "#004AAD" }}
            sx={{ backgroundColor: "#003882" }}
          >
            <Grid container direction="column" justifyContent="center">
              <Grid item>
                {/* <SportsScoreIcon
                  style={{ height: "2em", width: "2em", color: "white" }}
                /> */}
                <FaFlagCheckered
                  style={{ height: "2em", width: "2em", color: "white" }}
                />
                <Typography
                  sx={{
                    color: "white",
                    fontWeight: 500,
                    fontSize: "1rem",
                    marginBottom: "10px",
                  }}
                >
                  Objetivos
                </Typography>
              </Grid>
              <Grid item>
                <ul
                  style={{
                    padding: "0 0",
                    listStyle: "none",
                    display: "grid",
                    gap: "30px",
                  }}
                >
                  <li>
                    <Typography sx={{ color: "white", fontSize: "0.9rem" }}>
                      Contribuir al desarrollo de soluciones de movilidad
                      sustentable (personas y mercancías) que permitan mejorar
                      la calidad de vida de las comunidades y su entorno.
                    </Typography>
                  </li>
                  <li>
                    <Typography sx={{ color: "white", fontSize: "0.9rem" }}>
                      Ser un aporte a la cadena de valor de nuestros clientes,
                      adecuándonos a sus necesidades con un alto compromiso con
                      sus objetivos. Construir y mantener relaciones de
                      confianza de largo plazo con nuestros clientes que estén
                      basadas en la honestidad y el respeto mutuo.
                    </Typography>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </MyPaper>
        </Grid>
      </Grid>
    </Header>
  );
};

export default AboutUsSection;
