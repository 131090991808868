export const HomeObjOne = {
    id:'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Quiénes somos',
    headline: 'Empresa de ingeniería especializada en las áreas de Ingeniería de Transporte, Tecnología y Diseño',
    description: 'Nuestros objetivos son: Contribuir al desarrollo de soluciones de movilidad sustentable (personas y mercancías) que permitan mejorar la calidad de vida de las comunidades y su entorno.',
    buttonLabel: 'Get started',
    imgStarted: false,
    img: require('../../images/svg-1.svg').default,
    alt:'Car',
    dark: true,
    primary: true,
    darkText: false

}

export const Equipo = {
    id:'team',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Premium Banka',
    headline: 'Unlimited Transactions with zero fees',
    description: 'Get acces to our exclusive app that allows you to send unlimited transactions without getting charged any fees.',
    buttonLabel: 'Get started',
    imgStarted: true,
    img: require('../../images/svg-1.svg').default,
    alt:'Car',
    dark: false,
    primary: false,
    darkText: true

}

export const HomeObjThree = {
    id:'team',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Premium Bank',
    headline: 'Unlimited Transactions with zero fees',
    description: 'Get acces to our exclusive app that allows you to send unlimited transactions without getting charged any fees.',
    buttonLabel: 'Get started',
    imgStarted: true,
    img: require('../../images/svg-1.svg').default,
    alt:'Car',
    dark: false,
    primary: false,
    darkText: true

}

export const HomeObjFour = {
    id:'experience',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Premium Bank',
    headline: 'Unlimited Transactions with zero fees',
    description: 'Get acces to our exclusive app that allows you to send unlimited transactions without getting charged any fees.',
    buttonLabel: 'Get started',
    imgStarted: false,
    img: require('../../images/svg-1.svg').default,
    alt:'Car',
    dark: true,
    primary: true,
    darkText: false

}

export const HomeObjFive = {
    id:'clients',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Premium Bank',
    headline: 'Unlimited Transactions with zero fees',
    description: 'Get acces to our exclusive app that allows you to send unlimited transactions without getting charged any fees.',
    buttonLabel: 'Get started',
    imgStarted: true,
    img: require('../../images/svg-1.svg').default,
    alt:'Car',
    dark: false,
    primary: false,
    darkText: true

}

export const HomeObjSix = {
    id:'contact',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Premium Bank',
    headline: 'Unlimited Transactions with zero fees',
    description: 'Get acces to our exclusive app that allows you to send unlimited transactions without getting charged any fees.',
    buttonLabel: 'Get started',
    imgStarted: true,
    img: require('../../images/svg-1.svg').default,
    alt:'Car',
    dark: true,
    primary: true,
    darkText: false

}