import React from "react";
import { Grid, Paper, Button, Typography, TextField } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

const ContactForm = () => {
  const paperStyle = {
    padding: "40px 20px",
    maxWidth: 550,
    margin: "20px auto",
    width: "100%",
    height: "100%",
  };
  const btnStyle = { marginTop: 10 };
  const initialValues = {
    nombre: "",
    email: "",
    telefono: "",
    mensaje: "",
  };
  const validationSchema = Yup.object().shape({
    nombre: Yup.string().min(3, "Demasiado corto").required("Requerido"),
    email: Yup.string().email("Ingrese un email válido").required("Requerido"),
    telefono: Yup.number().typeError("Ingrese un número de telefono válido"),
    mensaje: Yup.string()
      .min(8, "Debe haber un mínimo de 8 caracteres")
      .required("Requerido"),
  });

  const onSubmit = (values, props) => {
    emailjs
      .send("service_2y6mdcp", "template_p5mh46c", values, "I8TCplpX6D12Ttj9_")
      .then(
        (result) => {
          console.log(result.text);
          Swal.fire({
            icon: "success",
            title: "Mensaje enviado con éxito",
          });
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            icon: "error",
            title: "Ups, algo salió mal",
            text: error.text,
          });
        }
      );
    props.resetForm();
  };

  return (
    <div style={{ display: "flex", position: "relative" }}>
      <Paper elevation={5} style={paperStyle}>
        <Grid align="center">
          <Typography
            style={{ marginBottom: 30, fontWeight: "bold" }}
            variant="h4"
          >
            Formulario de contacto
          </Typography>
        </Grid>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props) => (
            <Form>
              {/*<TextField label='Nombre' name='nombre' fullWidth 
                    value={props.values.nombre} onChange={props.handleChange}/>
                    <TextField label='Correo electrónico' fullWidth
                    {...props.getFieldProps('email')}/>
                  */}
              <Field
                as={TextField}
                label="Nombre"
                name="nombre"
                fullWidth
                error={props.errors.nombre && props.touched.nombre}
                helperText={<ErrorMessage name="nombre" />}
                required
              />
              <Field
                as={TextField}
                label="Correo electrónico"
                name="email"
                fullWidth
                error={props.errors.email && props.touched.email}
                helperText={<ErrorMessage name="email" />}
                required
              />
              <Field
                as={TextField}
                label="Teléfono"
                name="telefono"
                fullWidth
                error={props.errors.telefono && props.touched.telefono}
                helperText={<ErrorMessage name="telefono" />}
              />
              <Field
                as={TextField}
                label="Mensaje"
                multiline
                rows={6}
                name="mensaje"
                fullWidth
                error={props.errors.mensaje && props.touched.mensaje}
                helperText={<ErrorMessage name="mensaje" />}
                required
              />
              <Button
                style={btnStyle}
                type="submit"
                color="primary"
                variant="contained"
              >
                Enviar
              </Button>
            </Form>
          )}
        </Formik>
        {/** 
                <Formik
                initialValues={{ name: "" }}
                onSubmit={(values, actions) => {
                  setTimeout(() => {
                    SendEmail(values)
                    actions.setSubmitting(false)
                    }, 1000)
                }}
                >
                {(props) => (
                    <Form>
                    <Grid >
                        <Field name="name" validate={validateName}>
                            {({ field, form }) => (
                              <Box>
                                    <FormControl isInvalid={form.errors.name && form.touched.name}>
                                    <FormLabel htmlFor="name">First name</FormLabel>
                                    <Input {...field} id="name" placeholder="name" />
                                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                    </FormControl>
                                    </Box>
                            )}
                            </Field>
                    <Button
                    mt={4}
                        colorScheme="teal"
                        isLoading={props.isSubmitting}
                        type="submit"
                    >
                    Submit
                    </Button>
                    </Grid>
                    </Form>
                    )}
                </Formik>
                */}
      </Paper>
    </div>
  );
};

export default ContactForm;
