import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SidebarBtnWrap, SideBarRoute } from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon>
            <CloseIcon/>
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to={{pathname: "/"}} onClick={toggle}>Inicio</SidebarLink>
                <SidebarLink to={{pathname: "/nosotros"}} onClick={toggle}>Nosotros</SidebarLink>
                <SidebarLink to={{pathname: "/servicios"}} onClick={toggle}>Servicios</SidebarLink>
                <SidebarLink to={{pathname: "/equipo"}} onClick={toggle}>Equipo</SidebarLink>
                <SidebarLink to={{pathname: "/experiencia"}} onClick={toggle}>Experiencia</SidebarLink>
                <SidebarLink to={{pathname: "/clientes"}} onClick={toggle}>Clientes</SidebarLink>
                <SidebarLink to={{pathname: "/contacto"}} onClick={toggle}>Contacto</SidebarLink>
            </SidebarMenu>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar