//import logo from './logo.svg';
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Services from "./pages/Services";
import Experience from "./pages/Experience";
import Clients from "./pages/Clients";
import Contact from "./pages/Contact";
import Team from "./pages/Team";

function App() {
  return (
    <Router>
      <Routes>
        {/*<Route path="/" component={Home} exact />
        <Route path="/signin" component={SigninPage} exact />*/}
        <Route path="/" element={<Home />} exact />
        <Route path="/nosotros" element={<AboutUs />} exact />
        <Route path="/servicios" element={<Services />} exact />
        <Route path="/experiencia" element={<Experience />} exact />
        <Route path="/clientes" element={<Clients />} exact />
        <Route path="/contacto" element={<Contact />} exact />
        <Route path="/equipo" element={<Team />} exact />
        {/* <Route path="/signin" element={<SigninPage />} exact /> */}
      </Routes>
    </Router>
  );
}

export default App;
