import React, { useState } from "react";
import ClientsSection from "../components/ClientsSection";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

const Clients = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div style={{ backgroundColor: "#283556" }}>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <div style={{ height: "80px", backgroundColor: "#283556" }}></div>
      <ClientsSection />
      <div style={{ height: "10px", backgroundColor: "#283556" }}></div>
      <Footer />
    </div>
  );
};

export default Clients;
