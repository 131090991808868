import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import ContactForm from "./contactForm";
import {
  Header,
  InfoContainer,
  InfoWrapper,
  Heading,
  InfoRow,
  Column1,
  Column2,
  ServicesH1,
} from "./ContactFormElements";
import Gmap from "./Map/googleMap";
import MyMap from "./Map/MyMap";

const ContactFormSection = () => {
  return (
    <>
      <Header id="contact">
        {/*<Sección: Nosotros/>*/}
        <Typography variant="h3" sx={{ color: "#fff", marginBottom: "64px" }}>
          Contacto
        </Typography>
        <Container style={{ padding: "20px" }}>
          <Grid
            item
            container
            spacing={3}
            xs={12}
            // justifyContent="center"
            // alignContent="center"
            sx={{ marginTop: "-50px", marginBottom: "10px" }}
          >
            <Grid xs={12} sm={6} item justifyContent="center">
              <ContactForm />
            </Grid>

            <Grid
              item
              xs={10}
              sm={6}
              justifyContent="center"
              alignSelf="center"
              // className="col-lg-6 col-md-12 col-12"
              style={{
                display: "flex",
                // margin: "auto",
                // maxWidth: "550px",
                // height: "550px",
                // width: "100%",
                // height: "100%",
                // position: "relative",
              }}
            >
              <Grid
                style={{
                  // position: "absolute",
                  // width: "50%",
                  // height: "50%",
                  position: "relative",
                  // width: "200px",
                  // height: "200px",
                }}
              >
                {/* <Gmap /> */}
                <MyMap />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Header>
    </>
  );
};

export default ContactFormSection;
