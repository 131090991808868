import React, { Component } from "react";
import Slider from "react-slick";
import { Card, CardMedia, Grid, Typography } from "@mui/material";

//fotos clientes
import steer from "../../images/clientes/steer.svg";
import ryq from "../../images/clientes/ryq.svg";
import concesiones from "../../images/clientes/concesiones.svg";
import habiterra from "../../images/clientes/habiterra.svg";
import loprado from "../../images/clientes/loprado.svg";
import mstd from "../../images/clientes/mstd.svg";
import serviu from "../../images/clientes/serviu.svg";
import naipu from "../../images/clientes/naipu-mining.jpeg";
import wing from "../../images/clientes/w-ingenieria.jpeg";
import { ServicesH1 } from "../AboutUsSection/AboutUsElements";

import {
  ServicesContainer,
  ServicesWrapper,
  Img,
  ImgWrap,
  Img2,
  Img3,
} from "./ClientsElements";
import { Header } from "./ClientsElements2";

export default class ClientsSection extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 2500,
      cssEase: "linear",
    };
    return (
      <Header className="clientes" id="Clientes">
        {/*<Sección: Clientes/>*/}

        <Grid style={{ display: "flex", textAlign: "center" }}>
          <Typography variant="h3" sx={{ color: "#fff", marginBottom: "64px" }}>
            Nuestros Clientes
          </Typography>
        </Grid>
        <Grid>
          <Grid container justifyContent="center" sx={{}}>
            <Grid
              data-aos="zoom-in"
              item
              sm={5}
              md={3}
              style={{ margin: ".5em" }}
            >
              <Card
                sx={{
                  maxWidth: 300,
                  backgroundColor: "#e3f2fd",
                  padding: 6,
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CardMedia
                    sx={{
                      height: 100,
                      padding: 3,
                      backgroundColor: "#161B21",
                    }}
                    component="img"
                    image={steer}
                  />
                </div>
              </Card>
            </Grid>
            <Grid
              data-aos="zoom-in"
              item
              sm={5}
              md={3}
              style={{ margin: ".5em" }}
            >
              <Card sx={{ maxWidth: 300, backgroundColor: "#e3f2fd" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CardMedia
                    sx={{ maxHeight: 200, padding: 3 }}
                    component="img"
                    image={ryq}
                  />
                </div>
              </Card>
            </Grid>
            <Grid
              data-aos="zoom-in"
              item
              sm={5}
              md={3}
              style={{ margin: ".5em" }}
            >
              <Card sx={{ maxWidth: 300, backgroundColor: "#e3f2fd" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CardMedia
                    sx={{
                      height: 200,
                      width: 215,
                      padding: 2,
                      display: "inline",
                    }}
                    component="img"
                    image={concesiones}
                  />
                </div>
              </Card>
            </Grid>
            <Grid
              data-aos="zoom-in"
              item
              sm={5}
              md={3}
              style={{ margin: ".5em" }}
            >
              <Card sx={{ maxWidth: 300, backgroundColor: "#e3f2fd" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CardMedia
                    sx={{ maxHeight: 200, padding: 3 }}
                    component="img"
                    image={habiterra}
                  />
                </div>
              </Card>
            </Grid>
            <Grid
              data-aos="zoom-in"
              item
              sm={5}
              md={3}
              style={{ margin: ".5em" }}
            >
              <Card
                sx={{
                  maxWidth: 300,
                  backgroundColor: "#e3f2fd",
                  padding: 3,
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CardMedia
                    sx={{ maxHeight: 200, padding: 3 }}
                    component="img"
                    image={loprado}
                  />
                </div>
              </Card>
            </Grid>
            <Grid
              data-aos="zoom-in"
              item
              sm={5}
              md={3}
              style={{ margin: ".5em" }}
            >
              <Card sx={{ maxWidth: 300, backgroundColor: "#e3f2fd" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CardMedia
                    sx={{
                      height: 200,
                      width: 215,
                      padding: 2,
                      display: "inline",
                      margin: "auto",
                    }}
                    component="img"
                    image={serviu}
                  />
                </div>
              </Card>
            </Grid>
            <Grid
              data-aos="zoom-in"
              item
              sm={5}
              md={3}
              style={{ margin: ".5em" }}
            >
              <Card
                sx={{ maxWidth: 300, height: 200, backgroundColor: "#e3f2fd" }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CardMedia
                    sx={{ maxHeight: 200, padding: 7 }}
                    component="img"
                    image={mstd}
                  />
                </div>
              </Card>
            </Grid>
            <Grid
              data-aos="zoom-in"
              item
              sm={5}
              md={3}
              style={{ margin: ".5em" }}
            >
              <Card sx={{ maxWidth: 300, backgroundColor: "#e3f2fd" }}>
                <CardMedia
                  sx={{ maxHeight: 200, padding: 5 }}
                  component="img"
                  image={naipu}
                />
              </Card>
            </Grid>
            <Grid
              data-aos="zoom-in"
              item
              sm={5}
              md={3}
              style={{ margin: ".5em" }}
            >
              <Card
                sx={{
                  maxWidth: 300,
                  backgroundColor: "#e3f2fd",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <CardMedia
                  sx={{ maxHeight: 200, width: 200, padding: 3 }}
                  component="img"
                  image={wing}
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Header>
    );
  }
}
