import { Grid, Typography } from "@mui/material";
import React from "react";
import { Header } from "./TeamElements";
import foto1 from "../../images/equipo/GabrielV.jpeg";
import foto2 from "../../images/equipo/GabrielVD.jpg";

import FlippableCard from "./FlippableCard";

const TeamSection = () => {
  // var height = "140"; //140
  // var width = "80%";

  return (
    <Header>
      <Typography variant="h3" sx={{ color: "#fff", marginBottom: "64px" }}>
        Nuestro equipo
      </Typography>
      <>
        <Grid
          container
          xs={10}
          spacing={10}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item sm={6}>
            <FlippableCard
              foto={foto1}
              nombre={"Gabriel Vergara Hernández"}
              cargo={"CEO & Founder"}
              carrera={"Ingeniero Civil en Transporte U. de Chile"}
              correo={"gabrielvergarah@etd-consulting.cl"}
              link={
                "https://www.linkedin.com/in/gabriel-alfonso-vergara-hern%C3%A1ndez-8a01634a/"
              }
              detalle={
                "Cuenta con más de 24 años de experiencia en el rubro de la consultoría de movilidad de bienes y personas, se ha desempeñado como Director y Jefe de proyecto e ingeniero especialista en múltiples estudios del ámbito público y privado en materia de infraestructura de transporte. Ha participado como especialista en estudios de evaluación social de proyectos, modelación estratégica y táctica, estudios de capacidad e impacto vial, estudios de demanda y recaudación para autopistas de peaje, optimización de servicios de transporte público (definición de paraderos y recorridos) y modelos de microsimulación para verificación de diseños."
              }
            />
          </Grid>
          <Grid item sm={6}>
            <FlippableCard
              foto={foto2}
              nombre={"Gabriel Vergara Donoso"}
              cargo={"Head of Technology"}
              carrera={"Ingeniero Civil en Informatica U. Santa María"}
              correo={"gabrielvergarad@etd-consulting.cl"}
              link={"https://www.linkedin.com/in/gabrielvergaradonoso/"}
              detalle={
                "Como Ingeniero Civil Informático, cuenta con experiencia en diversas áreas, como el desarrollo de software, análisis de datos y configuración de servidores web."
              }
            />
          </Grid>
        </Grid>
      </>
    </Header>
  );
};

export default TeamSection;
