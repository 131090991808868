import React, { useEffect, useState } from "react";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 15) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
              ETD Consulting
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks to={{ pathname: "/" }}>Inicio</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to={{ pathname: "/nosotros" }}>Nosotros</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to={{ pathname: "/servicios" }}>Servicios</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to={{ pathname: "/equipo" }}
                  // smooth={true} duration={500} spy={true} exact='true' offset={-80}
                >
                  Equipo
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to={{ pathname: "/experiencia" }}>
                  Experiencia
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to={{ pathname: "/clientes" }}>Clientes</NavLinks>
              </NavItem>
              <NavItem>
                {/* <NavLinks to="contact"
                        smooth={true} duration={500} spy={true} exact='true' offset={-80}
                        >Contacto</NavLinks> */}
                <NavLinks to={{ pathname: "/contacto" }}>Contacto</NavLinks>
              </NavItem>
            </NavMenu>
            {/*<NavBtn>
                    <NavBtnLink to="/signin"> Sign In</NavBtnLink>
  </NavBtn>*/}
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
