import styled from "styled-components";

export const Header = styled.header`
  background-color: #283556;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #1f1f1f;
`;
