import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { MarkerF } from "@react-google-maps/api";
import "./marker.css";
import CircularProgress from "@mui/material/CircularProgress";

const containerStyle = {
  width: "300px",
  height: "300px",
};

const location = {
  address: "Emilio lailhacar 6051, San Miguel.",
  lat: -33.51173201160969,
  lng: -70.65177350003998,
}; // our location object from earlier

// const options = {
//   zoomControlOptions: {
//     position: google.maps.ControlPosition.RIGHT_CENTER // 'right-center' ,
//     // ...otherOptions
//   }
// }

export default function MyMap() {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyD-1E5rs8-Nl0YSsVy5a9v_43sKLus3Oeo", // ,
    // ...otherOptions
  });

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    // const onLoad = React.useCallback(
    //   function onLoad (mapInstance) {
    //     // do something with map Instance
    //   }
    // )
    const onLoad = (marker) => {
      console.log("marker: ", marker);
    };
    return (
      <GoogleMap mapContainerStyle={containerStyle} center={location} zoom={17}>
        <MarkerF
          onLoad={onLoad}
          position={location}
          // label={location.address}
        />
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <CircularProgress />;
}
